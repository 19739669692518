@import url('https://fonts.googleapis.com/css2?family=Teko&display=swap');

.expand-icon {
    float: right;
    padding: 0.2rem 0.5rem;
    height: 100%;
    align-items: center;
    line-height: normal;
    border: 1px solid black;
    background-color: transparent;
    border-radius: 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .expand-icon:hover {
    background-color: blue;
  }

  .left-col {
    width: 70%;
  }

  .right-col {
    width: 30%;
  }

  .scrollable-table {
    max-height: 500px; /* Adjust this value to your needs */
    background-color: white;
    overflow-y: auto;
    display: block;
    border: 2px;
  }

  .scrollable-table th {
    position: sticky;
    top: 0;
    z-index: 2; /* Ensure the header is above the rest of the table content */
  }

  .title {
    font-family: 'Teko', sans-serif;
    text-shadow: 1px 1px 2px white; /* horizontal offset, vertical offset, blur radius, color */
  }

  /* Add this to your CSS file */
.selected-row, .selected-row td {
  background-color: #3595b4 !important; /* Using !important to override Bootstrap styles if necessary */
}

.history-header {
  display: flex; /* Enables Flexbox */
  justify-content: space-between; /* Aligns children to each side */
  align-items: center; /* Centers items vertically */
}