@import url('https://fonts.googleapis.com/css2?family=Teko&display=swap');

.my-container {
  overflow-x: auto; /* Enable horizontal scrolling if content exceeds container width */
}

.my-container::-webkit-scrollbar {
    width: 8px;
    height: 12px;
    background-color: transparent;
}

.my-container::-webkit-scrollbar-track {
    background-color: transparent;
}

.my-container::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    background-color: #0d6efd;
}

.images-wrapper {
  display: flex; /* Use Flexbox to make the image wrappers align horizontally */
}

.image-item {
  flex: 0 0 12%; /* Each image wrapper takes up 25% of the container's width */
  margin: 3px;
  background-color: white;
  box-sizing: border-box; /* Include padding in the item's width calculation */
}

.highlighted {
    border: 5px solid #0d6efd !important;
}

.image-item img {
  width: 100%; /* Make the images inside the wrapper take up the full width of the wrapper */
  height: auto; /* Preserve the aspect ratio of the images */
}

.title {
  font-family: 'Teko', sans-serif;
  text-shadow: 1px 1px 2px white; /* horizontal offset, vertical offset, blur radius, color */
}

input:disabled {
  background-color: #5a5555 !important; /* Dark grey background */
  color: white; /* White text color */
}

.casting-toggle-buttons {
  display: flex;
  gap: 10px;
}

.casting-toggle-buttons button {
  flex: 1;
  width: 100%;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  border: none;
  color: white; /* White text color for better contrast */
}

.casting-toggle-buttons .selected {
  background-color: #0d6efd; /* Blue background for selected button */
}

.casting-toggle-buttons .unselected {
  background-color: #5a5555; /* Dark grey background for unselected button */
}
